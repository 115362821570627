<template>
  <div id="credit_keep_item">
    <div class="animate__animated animate__fadeIn animate__slow">
      <el-row :gutter="30" v-if="data.length > 0" class="flex-warp is-flex">
        <el-col
          :span="24"
          :sm="12"
          :mg="8"
          :lg="6"
          v-for="(row, index) in data"
          :key="index"
          class="mg-y-5 animate__animated animate__fadeIn animate__slow is-flex"
          style="position: relative"
        >
          <el-card
            class="card-normal keep-active"
            :class="checkDateExp(row.registerEnd) == true ? 'close' : ''"
            shadow="never"
          >
            <div class="box-img">
              <img
                v-if="row.image != ''"
                :src="imgUrlTERAK + row.image"
                alt=""
                @click="$emit('morePicture', imgUrlTERAK + row.image)"
                class="img-content cursor-pointer"
              />
              <img
                v-else
                :src="require(`@/assets/image-default.jpg`)"
                alt=""
                class="img-content"
              />
              <div
                class="time-out"
                v-if="checkDateExp(row.registerEnd) == true"
              >
                <span>
                  <i class="fas fa-calendar-alt mg-r-7"></i>ปิดรับสมัคร เมื่อ
                  {{ row.registerEnd | formatDateTHNumber }}</span
                >
              </div>
            </div>

            <div class="detail-card">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.projectName"
                placement="bottom-start"
              >
                <p class="title">{{ row.projectName }}</p>
              </el-tooltip>
              <p style="color: #034ea2">
                <i class="fas fa-calendar-alt mg-r-7"></i>เปิดรับสมัคร
                {{ row.registerStart | formatDate }}
              </p>
              <p style="color: #ed1c24">
                <i class="fas fa-calendar-alt mg-r-7"></i>ปิดรับสมัคร
                {{ row.registerEnd | formatDate }}
              </p>
              <p
                class="content"
                v-if="
                  row.projectDescription != null &&
                  row.projectDescription != 'null' &&
                  row.projectDescription != '' &&
                  checkScriptHTML(row.projectDescription)
                "
              >
                {{ row.projectDescription | striphtml }}
              </p>

              <p class="see-more">
                <span
                  @click="goToLink(`/manage/${pageType}/detail/${row._id}`)"
                  class="cursor-pointer"
                >
                  <span>อ่านรายละเอียด </span>
                </span>
                <!-- <el-button
                  class="btn-kep-item"
                  @click="handleKeepItemCancle(row._id)"
                  style="margin-top: 5px"
                  size="mini"
                  ><i class="fa fa-archive" aria-hidden="true"></i>
                  ยกเลิกจัดเก็บโครงการ</el-button
                > -->
              </p>
              <div class="text-center mobile-edit">
                <span
                  v-if="isShowKeepBtn()"
                  class="btn-kep-item"
                  @click="handleKeepItemCancle(row._id)"
                  size="mini"
                >
                  <i class="fas fa-trash-restore" aria-hidden="true"></i> ยกเลิก

                  <!-- <i class="fa fa-archive" aria-hidden="true"></i> -->
                </span>
                <span
                  v-if="isShowKeepBtn()"
                  class="btn-copy-item"
                  @click="copyItem(row._id)"
                  size="mini"
                  ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
                </span>
                <router-link
                  :to="`/manage/${pageType}/edit/${row._id}`"
                  class="edit-card"
                  v-if="permissionMore == true"
                >
                  <i class="fas fa-edit"></i>
                  แก้ไข
                </router-link>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="text-center mg-t-3 font-30 font-weight-500" v-else>
        <span v-if="dataSearch != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
        <span v-else> ไม่มีข้อมูล </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: [
    "data",
    "pageNow",
    "totalPage",
    "limit",
    "dataSearch",
    "permissionMore",
    "pageType",
    "typeTable",
  ],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
  methods: {
    handleKeepItemCancle(id) {
      this.$emit("diaLogsKeepProject", id);
    },
    isShowKeepBtn() {
      let custom = this.user.custom || 0;
      let permission = this.user.role.permission;
      if (custom == 1) {
        permission = this.user.permissionCustom;
      }
      const hasClosed = permission.some((e) => e.pemNum == 15 && e.status == 2);
      return hasClosed;
    },
    checkScriptHTML(value) {
      const strippedString = value.replace(/(<([^>]+)>)/gi, "");
      let rep = new RegExp("&nbsp;", "g");
      let setReplace = strippedString.replaceAll(rep, "");
      if (setReplace != null && setReplace != "null" && setReplace != "") {
        return true;
      } else {
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    handleCurrentChangeKeep(val) {
      this.$emit("changePage", val);
    },
    copyItem(id) {
      this.$emit("copyItem", id, this.typeTable);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>

<style>
</style>