<template>
  <el-card
    class="card-normal"
    :class="checkDateExp(data.registerEnd) == true ? 'close' : ''"
    shadow="never"
  >
    <div class="box-img">
      <img
        v-if="data.image != ''"
        :src="imgUrlTERAK + data.image"
        alt=""
        @click="$emit('morePicture', imgUrlTERAK + data.image)"
        class="img-content cursor-pointer"
      />
      <img
        v-else
        :src="require(`@/assets/image-default.jpg`)"
        alt=""
        class="img-content"
      />
      <div class="time-out" v-if="checkDateExp(data.registerEnd) == true">
        <span>
          <i class="fas fa-calendar-alt mg-r-7"></i>ปิดรับสมัคร เมื่อ
          {{ data.registerEnd | formatDateTHNumber }}</span
        >
      </div>
    </div>

    <div class="detail-card">
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.projectName"
        placement="bottom-start"
      >
        <p class="title">{{ data.projectName }}</p>
      </el-tooltip>
      <p style="color: #034ea2">
        <i class="fas fa-calendar-alt mg-r-7"></i>เปิดรับสมัคร
        {{ data.registerStart | formatDate }}
      </p>
      <p style="color: #ed1c24">
        <i class="fas fa-calendar-alt mg-r-7"></i>ปิดรับสมัคร
        {{ data.registerEnd | formatDate }}
      </p>
      <p
        class="content"
        v-if="
          data.projectDescription != null &&
          data.projectDescription != 'null' &&
          data.projectDescription != '' &&
          checkScriptHTML(data.projectDescription)
        "
      >
        {{ data.projectDescription | striphtml }}
      </p>

      <p class="see-more">
        <span
          @click="goToLink(`/manage/${pageNow}/detail/${data._id}`)"
          class="cursor-pointer"
        >
          <span>อ่านรายละเอียด </span>
        </span>
      </p>
      <div class="text-center mobile-edit">
        <span
          v-if="handleKeepBtn()"
          class="btn-kep-item"
          @click="handleKepItem(data._id)"
          size="mini"
          ><i class="fa fa-archive" aria-hidden="true"></i> จัดเก็บ
        </span>
        <span
          v-if="handleKeepBtn()"
          class="btn-copy-item"
          @click="copyItem(data._id)"
          size="mini"
          ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
        </span>
        <router-link
          :to="`/manage/${pageNow}/edit/${data._id}`"
          class="edit-card"
          :class="!checkKeepByRole ? 'right' : ''"
          v-if="permissionMore == true"
        >
          <i class="fas fa-edit"></i>
          แก้ไข
        </router-link>
      </div>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: ["data", "pageNow", "permissionMore", "checkKeepByRole"],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
  methods: {
    handleKeepBtn() {
      let hasCustom = this.user.custom || 0;
      // console.log('custom', hasCustom);
      let permission = this.user.role.permission;
      if (hasCustom == 1) permission = this.user.permissionCustom;
      const hasClosed = permission.some((e) => e.pemNum == 15 && e.status == 2);
      return hasClosed;
    },
    checkScriptHTML(value) {
      const strippedString = value.replace(/(<([^>]+)>)/gi, "");
      let rep = new RegExp("&nbsp;", "g");
      let setReplace = strippedString.replaceAll(rep, "");
      if (setReplace != null && setReplace != "null" && setReplace != "") {
        return true;
      } else {
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    goToLink(data) {
      this.$router.push(data);
    },
    copyItem(id) {
      this.$emit("copyItem", id);
    },
    handleKepItem(id) {
      this.$emit("diaLogsKeepProject", id);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>