<template>
  <DefaultTemplate
    id="project-page"
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <p class="title">จัดการข้อมูลโครงการ</p>
      <div class="is-flex ai-center">
        <el-switch
          v-if="checkKeepByRole()"
          class="mg-r-7"
          v-model="switchChangeType"
          :active-text="
            switchChangeType
              ? 'แสดงโครงการที่ถูกจัดเก็บ'
              : 'ซ่อนโครงการที่ถูกจัดเก็บ'
          "
          @change="handleKeepItem"
        >
        </el-switch>
        <el-tooltip
          class="item"
          effect="dark"
          content="ค้นหาโครงการ"
          placement="bottom"
        >
          <el-input
            id="searchProject"
            placeholder="ค้นหาโครงการ"
            suffix-icon="el-icon-search"
            v-model="wordProject"
            class="search"
            @input="searchProject"
            clearable
          >
          </el-input>
        </el-tooltip>
        <el-button
          id="changeShowTable"
          type="primary"
          class="pd-x-5"
          @click="changeType"
        >
          <i class="fas fa-th mg-0" v-if="tableTypeProject == 1"></i>
          <i class="fas fa-th-list mg-0" v-else-if="tableTypeProject == 2"></i>
        </el-button>
        <el-button
          id="btnAddProject"
          type="primary"
          @click="$router.push('/manage/project/add')"
          v-if="checkPermissionMore()"
        >
          <i class="fas fa-plus"></i>เพิ่มข้อมูลโครงการ
        </el-button>
      </div>
    </div>
    <div
      v-loading.lock="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <div
        v-loading.lock="loadingNormal"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <el-row
          :gutter="30"
          v-if="tableTypeProject == 1"
          class="mh-card flex-warp is-flex"
        >
          <el-col
            :span="24"
            :sm="12"
            :mg="8"
            :lg="6"
            v-for="(dataProject, index) in dataProject"
            :key="index"
            class="
              mg-y-5
              animate__animated animate__fadeIn animate__slow
              is-flex
            "
            style="position: relative"
          >
            <CardProject
              :checkKeepByRole="checkKeepByRole()"
              :data="dataProject"
              :pageNow="`project`"
              :permissionMore="checkPermissionMore()"
              @diaLogsKeepProject="handleDialogsKepItem"
              @morePicture="openDialogPicture"
              @copyItem="copyItem"
            />
          </el-col>
          <el-col :span="24">
            <div class="text-center mg-t-3" v-if="dataProject.length == 0">
              <p class="font-30 font-weight-500" v-if="!loading">
                <span v-if="wordProject != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
                <span v-else> ไม่มีข้อมูล </span>
              </p>
            </div>
          </el-col>
        </el-row>
        <div class="box-card mg-y-2" v-if="tableTypeProject == 2">
          <TableManage
            :loadTERAK="loadTERAK"
            :optionsCates="optionsCates"
            :optionsQuestion="optionsQuestion"
            :data="dataProject"
            :page="`project`"
            :permissionMore="checkPermissionMore()"
            :checkKeepByRole="checkKeepByRole()"
            :loading="loading"
            @keepTable="handleDialogsKepItem"
            @copyItem="copyItem"
          />
        </div>
        <div class="text-center mg-t-5" v-if="dataProject.length > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="changePage"
            :current-page.sync="page"
            :page-sizes="[8, 30, 50, 100, 500]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div
        v-if="switchChangeType"
        v-loading.lock="loadingKeep"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <div v-if="switchChangeType && !loading">
          <hr class="mg-b-5" />
          <div class="box-name-page mg-b-7">
            <p class="title">ข้อมูลโครงการ ที่ถูกจัดเก็บ</p>
          </div>
          <div v-if="tableTypeProject == 1 && checkKeepByRole()">
            <ProjectKeep
              :pageType="`project`"
              :pageNow="pageNowKeep"
              :data="dataKeep"
              :totalPage="totalPageKeep"
              :limit="limit"
              :typeTable="`keep`"
              :dataSearch="wordProject"
              :permissionMore="checkPermissionMore()"
              @changePage="changePageKeep"
              @diaLogsKeepProject="handleDialogsKeepItemCancle"
              @copyItem="copyItem"
            />
          </div>
          <div class="animate__animated animate__fadeIn animate__slow">
            <div class="box-card mg-y-5" v-if="tableTypeProject == 2">
              <TableManage
                :loadTERAK="loadTERAK"
                :optionsCates="optionsCates"
                :optionsQuestion="optionsQuestion"
                :data="dataKeep"
                :page="`project`"
                :typeTable="`keep`"
                :loading="loading"
                :permissionMore="checkPermissionMore()"
                @keepTable="handleDialogsKepItem"
                @cancleKeepTable="handleDialogsKeepItemCancle"
                @copyItem="copyItem"
              />
            </div>
          </div>
          <div class="text-center mg-t-5" v-if="dataKeep.length > 0">
            <el-pagination
              @size-change="handleSizeChangeKeep"
              @current-change="changePageKeep"
              :current-page.sync="pageNowKeep"
              :page-sizes="[8, 30, 50, 100, 500]"
              :page-size="limitKeep"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalPageKeep"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
    <!-- Dialogs Kep Credit -->
    <el-dialog
      :visible.sync="modalKeep"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะจัดเก็บโครงการหรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalKeep = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitKeepProject"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
    <!-- End Dialogs Kep Credit -->
    <el-dialog
      :visible.sync="modalKeepCancle"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">
          คุณต้องการที่จะยกเลิกจัดเก็บโครงการหรือไม่
        </p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalKeepCancle = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitKeepProjectCancle"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalCopy"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะคัดลอกโครงการหรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalCopy = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitCopyItem"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import ProjectKeep from "@/components/project/ProjectKeep";
import CardProject from "@/components/card/CardProject";
import TableManage from "@/components/table/TableManage";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

import { TERAK } from "@/service/axios";
import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);
export default {
  components: {
    DefaultTemplate,
    CardProject,
    TableManage,
    ProjectKeep,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      permissionNow: (state) => state.permissionNow,
      tableTypeProject: (state) => state.tableTypeProject,
      showHideProject: (state) => state.showHideProject,
    }),
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.$store.commit("SET_PAGE_ACTIVE", "manage/project");
    this.getProject();
    this.getProjectKeep();
    this.switchChangeType = this.showHideProject;
    this.getByTokenTerak("CFOE2021");
  },
  data() {
    return {
      wordProject: "",
      total: 10,
      limit: 8,
      limitKeep: 8,
      page: 1,
      pageNowKeep: 1,
      dataProject: [],
      permissionProject: "",
      loading: false,
      debounce: null,
      imgMore: "",
      dialogPicture: false,
      dataKeep: [],
      modalKeepCancle: false,
      kepId: null,
      switchChangeType: null,
      modalKeep: false,
      totalPageKeep: null,
      loadTERAK: false,
      optionsCates: [],
      optionsQuestion: [],
      loadingNormal: false,
      loadingKeep: true,
      loadingData: true,
      modalCopy: false,
      typeKeep: null,
    };
  },
  methods: {
    getByTokenTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        TERAK.get(`cfoe/cates/questions`)
          .then((res) => {
            if (res.data.success) {
              this.loadTERAK = true;
              this.optionsCates = res.data.cates;
              this.optionsQuestion = res.data.questions;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    checkKeepByRole() {
      let hasCustom = this.user.custom || 0;
      let permission = this.user.role.permission;
      if (hasCustom == 1) permission = this.user.permissionCustom;
      const hasClosed = permission.some((e) => e.pemNum == 15 && e.status != 3);
      return hasClosed;
    },
    checkKeepByPermission() {
      let hasCustom = this.user.custom || 0;
      let permission = this.user.role.permission;
      if (hasCustom == 1) permission = this.user.permissionCustom;
      const hasClosed = permission.some((e) => e.pemNum == 15 && e.status == 2);
      return hasClosed;
    },
    handleKeepItem() {
      if (this.switchChangeType == true) {
        this.$store.commit("SET_SHOW_PROJECT_HIDE", true);
      } else {
        this.$store.commit("SET_SHOW_PROJECT_HIDE", false);
      }
    },
    handleDialogsKepItem(id) {
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalKeep = true;
      }
    },
    handleDialogsKeepItemCancle(id) {
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalKeepCancle = true;
      }
    },
    async submitKeepProject() {
      this.loading = true;
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.put(`project/hidden/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalKeep = false;
              this.$message({
                message: "จัดเก็บโครงการ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              this.getProject();
              this.getProjectKeep();
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },
    async submitKeepProjectCancle() {
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.put(`project/hidden/cancel/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalKeepCancle = false;
              this.$message({
                message: "ยกเลิกจัดเก็บโครงการ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              this.getProject();
              this.getProjectKeep();
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },

    async getProjectKeep() {
      const configs = {
        "Content-type": {
          Authorization: `Bearer ${this._token}`,
        },
      };
      const response = await HTTP.post(
        "project/hidden",
        {
          page: this.pageNowKeep,
          limit: this.limitKeep,
          key: this.wordProject,
        },
        configs
      );

      if (response.data.success == true) {
        this.totalPageKeep = response.data.obj.totalPage;

        this.dataKeep = response.data.obj.data;
        this.loadingKeep = false;
      }
    },
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permissionManage && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    searchProject() {
      this.page = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getProject();
        this.getProjectKeep();
      }, 600);
    },
    async getProject() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.page,
        limit: this.limit,
        key: this.wordProject,
      };
      let res = await HTTP.post(`project`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataProject = res.data.obj.data;
            this.dataProject.forEach((project) => {
              project.factor.forEach((factor) => {
                factor.score = factor.score.sort();
              });
            });
            this.total = res.data.obj.totalPage;
            this.loadingData = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          this.loadingData = false;
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loading = false;
          this.loadingNormal = false;
        });
      return res;
    },
    changePageKeep(val) {
      this.loadingKeep = true;
      this.pageNowKeep = val;
      this.getProjectKeep();
    },
    handleSizeChangeKeep(val) {
      this.loadingKeep = true;
      this.limitKeep = val;
      this.changePageKeep(1);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.loadingNormal = true;
      this.page = page;
      setTimeout(() => {
        this.getProject();
      }, 600);
    },
    changeType() {
      if (this.tableTypeProject == 1) {
        this.$store.commit("SET_SHOW_PROJECT", 2);
      } else if (this.tableTypeProject == 2) {
        this.$store.commit("SET_SHOW_PROJECT", 1);
      }
    },
    copyItem(id, type) {
      this.typeKeep = type;
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalCopy = true;
      }
    },
    async submitCopyItem() {
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.get(`project/duplicate/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalCopy = false;
              this.$message({
                message: "คัดลอกโครงการ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              if (this.typeKeep == "keep") {
                this.loadingKeep = true;
                await this.getProjectKeep();
              } else {
                this.loadingNormal = true;
                await this.getProject();
              }
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },
  },
};
</script>
